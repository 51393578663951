import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@/components/ui/button';
import { useAssetList } from '@/hooks/data/assets/useAssetList';
import { useTree } from '@/hooks/data/tree/useTree';
import { useUserSettings } from '@/context/UserSettingsContext';
import { DamLayout } from '@/components/layout';
import { GalleryLayout } from '@/components/gallery-layout';
import { useSort } from '@/hooks/useSort';
import { useSearchQuery } from '@/hooks/useSearchQuery';
import { EntityType } from '@/types/entity';

export default function Home() {
  const { isAuthenticated } = useAuth0();
  const { tree, isRootNodesLoading, includeSubFolderAssets } = useTree();
  const { assetsPerPage } = useUserSettings();
  const { query, push, replace, pathname } = useRouter();
  const { results, page, folder, album } = query;

  const { sort, handleOnSortChange, handleOnSortOrderChange } = useSort(EntityType.Asset);
  const { queryString, debouncedSearch, showFilterInput, setShowFilterInput } = useSearchQuery();
  const { error, assets, refetch } = useAssetList(
    Number(page ?? 1),
    Number(results ?? 10),
    folder && String(folder),
    album && String(album),
    includeSubFolderAssets,
    sort,
    queryString,
  );

  const setQueryParams = (value: string, order: string, qString: string) => {
    const newQuery = {
      ...query,
      sortValue: value,
      sortOrder: order,
      queryString: qString,
    };

    void replace(
      {
        pathname: pathname,
        query: newQuery,
      },
      undefined,
      { shallow: true },
    );
  };

  useEffect(() => {
    setQueryParams(sort.value, sort.order, queryString);
  }, [sort.value, sort.order, queryString]);

  useEffect(() => {
    if (isAuthenticated && !isRootNodesLoading && tree) {
      void push(
        {
          // Select first folder from the tree on initial load
          query: { folder: tree[0]?.node.id, page: 1, results: assetsPerPage ?? 10, ...query },
        },
        undefined,
        { shallow: true },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, tree]);

  return error ? (
    <div className="flex h-screen flex-col items-center justify-center">
      <h1 className="mb-4 text-3xl font-bold text-neutral-950 dark:text-neutral-50">Oops! Something went wrong.</h1>
      <p className="mb-8 text-lg text-neutral-600 dark:text-neutral-400">Please try again later.</p>
      <Button onClick={() => refetch()}>Retry</Button>
    </div>
  ) : (
    <DamLayout assets={assets?.assets} showAssetSizeSlider showAssetsPerPageMenu showGalleryLayoutOptions>
      <GalleryLayout
        data={assets}
        pageTitle={'All'}
        pageDescription={'All'}
        resizeValue={0}
        sort={sort}
        handleOnSortChange={handleOnSortChange}
        handleOnSortOrderChange={handleOnSortOrderChange}
        queryString={queryString}
        handleOnSearch={debouncedSearch}
        showFilterInput={showFilterInput}
        setShowFilterInput={setShowFilterInput}
      />
    </DamLayout>
  );
}
